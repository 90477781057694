<!---中奖纪录-->
<template>
  <div class="active-wrapper">
    <div v-if="list && list.length > 0" class="active-wrapper">
      <scroller ref="scroll" :on-refresh="refresh" :on-infinite="infinite">
        <!-- 列表上的内容 -->
        <!-- 列表数据 -->
        <ul class="page-myloadmore-list active_list">
          <li v-for="(item, index) in list" :key="index" class="active_item">
            <div style="padding: 5px 10px;width:100%; display: flex; justify-content: space-between">
              <div>{{ item.name }}</div>
              <div class="active_title2">
                <span>{{ item.created_at }}</span>
              </div>
            </div>
          </li>
        </ul>
      </scroller>
    </div>
    <null v-else class="null"></null>
  </div>
</template>
<script>
export default {
  props: ["list", "isMore", "loading"],
  data() {
    return {};
  },
  methods: {
    handlefinishInfinite() {
      this.$refs.scroll.finishInfinite(false);
    },
    refresh(done) {
      let timer = null;
      clearTimeout(timer);
      timer = setTimeout(() => {
        this.$emit("refresh");
        done();
        this.$refs.scroll.finishInfinite(false);
      }, 1500);
    }, //刷新
    infinite(done) {
      if (this.isMore) {
        setTimeout(() => {
          this.$emit("more");
          done(true);
        }, 1500);
      } else done(true);
    },
  },
  components: {
    null: () => import("@/common/null"),
  },
};
</script>

<style lang="less" scope>
.active-wrapper {
  left: 0;
  top: 0;
  width: 100%;
  height: 88vh;
  .page-myloadmore-list {
    list-style: none;
  }
}

</style>
